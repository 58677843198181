/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bxslider/dist/jquery.bxslider.min.css';
import './scss/font.scss';
import './scss/global.scss';
import './scss/agency.scss';
import './scss/home.scss';
import './scss/offer.scss';
import './scss/offer_detail.scss';
import './scss/reference.scss';
import './scss/contact.scss';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
const $ = require('jquery');
global.$ = global.jQuery = $;

require('bootstrap');
require('popper.js');
require('@fortawesome/fontawesome-free')
require('bxslider/dist/jquery.bxslider.min');
require('./js/home');
require('./js/reference');
