jQuery(document).ready(function () {
    if (jQuery('div[data-module="home"]').length > 0) {
        jQuery('.bxslider').bxSlider({
            mode: 'horizontal',
            touchEnabled: false,
            auto: true,
            pause: 10000,
            prevText: '<i class="fas fa-chevron-left"></i>',
            nextText: '<i class="fas fa-chevron-right"></i>',
            speed: 1000,
            autoHover: true,
            useCSS: false
        });

        jQuery(document).bind('scroll', function(ev) {
            const d = new Date();
            var scrollOffset = jQuery(document).scrollTop();
            var containerOffset = jQuery('#experience-number').offset().top - window.innerHeight;
            if (scrollOffset > containerOffset) {
                // var animate = setInterval(function() {
                    animateValue('experience-number', 0, d.getFullYear() - 2009, 3000);
                    animateValue('application-number', 0, 80, 3000);
                    animateValue('idear-number', 0, 1000, 3000);
                // }, time);
                // unbind event
                jQuery(document).unbind('scroll');
            }
        });
    }
});

function animateValue(id, start, end, duration) {
    // assumes integer values for start and end

    var obj = document.getElementById(id);
    var range = end - start;
    // no timer shorter than 50ms (not really visible any way)
    var minTimer = 50;
    // calc step time to show all interediate values
    var stepTime = Math.abs(Math.floor(duration / range));

    // never go below minTimer
    stepTime = Math.max(stepTime, minTimer);

    // get current time and calculate desired end time
    var startTime = new Date().getTime();
    var endTime = startTime + duration;
    var timer;

    function run() {
        var now = new Date().getTime();
        var remaining = Math.max((endTime - now) / duration, 0);
        var value = Math.round(end - (remaining * range));
        obj.innerHTML = value;
        if (value == end) {
            clearInterval(timer);
        }
    }

    timer = setInterval(run, stepTime);
    run();
}
