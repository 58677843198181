jQuery(document).ready(function () {
    if (jQuery('div[data-module="reference"]').length > 0) {
        jQuery('.reference-filter').click(function () {
            jQuery('.reference-filter').addClass('btn-brown').removeClass('btn-green');
            jQuery(this).removeClass('btn-brown').addClass('btn-green');
            if (jQuery(this).data('project-type-id')) {
                jQuery('.references .reference-hook').hide();
                jQuery('.references div[data-project-types-id*="' + jQuery(this).data('project-type-id') + '"]').show();
            } else {
                jQuery('.references .reference-hook').show();
            }
        });
    }
});
